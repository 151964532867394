.bodyimagesplit-block {
    @include centred-mobile;
    margin-bottom: 0;
    .body.above {
        margin-left: $spacing-mobile;
        margin-right: $spacing-mobile;
    }
    .bodyimagesplit-block-inner {
        @include breakpoint($size-768) {
            @include nest;
            @include tablefy($spacing-mobile, bottom, true);
        }

        .body {
            padding-bottom: $spacing;
            padding-left: $spacing-mobile;
            @include breakpoint($size-768) {
                width: span(7);
                float: left;
            }
        }
        .image {
            @include breakpoint($size-768) {
                width: span(5);
                float: right;
            }
        }
    }
}