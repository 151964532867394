#cookie-policy {
    background: none repeat scroll 0 0 $white;
    bottom: 0;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.66);
    padding: 20px;
    position: fixed;
    right: 0;
    width: 100%;
    display: none;
    z-index: 9999;
    .contain {
    	 @include container-render;
    }
    @include breakpoint(tablet-horizontal) {
        width: 425px;
        margin: 10px 20px;
    }
    span.title {
        margin: 5px 0 5px 0;
        font-size: em(25px);
        line-height: 30px;
        color: $brand-primary;
        display: block;
    }
}