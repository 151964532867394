@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

@mixin replace-with-image($icon) {
    @include spritesheet-sprite($icon);
    height: spritesheet-sprite-height($icon);
    width: spritesheet-sprite-width($icon);
    @include hide-text;
}

@mixin arrows-double($type) {
    position: relative;
    padding-right: 15px;
    &:after {
        display: block;
        content: '';
        position: absolute;
        right: 0px;
        top: 5px;
        @include replace-with-image($type);
    }
}

//This mixin will need to be upated if used, to add the correct padding and poisitioning. 
//Currently this is just a duplicate of arrows-double mixin.
@mixin arrow($type) {
    position: relative;
    padding-right: 15px;
    &:after {
        display: block;
        content: '';
        position: absolute;
        right: 0px;
        top: 5px;
        @include replace-with-image($type);
    }
}

@mixin side-pad {
    padding-left: $grid-padding;
    padding-right: $grid-padding;
}

// Sizing shortcuts
@mixin size($width, $height) {
    width: $width;
    height: $height;
}

// Single side border-radius
@mixin border-top-radius($radius) {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
    &::-moz-placeholder, &:-moz-placeholder {
        color: $color;
        // Firefox
        opacity: 1;
    }
    // See https://github.com/twbs/bootstrap/pull/11526
    &:-ms-input-placeholder {
        color: $color;
    }
    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: $color;
    }
    // Safari and Chrome
    &.placeholdered {
        color: $color;

        &:focus, &:active {
            color: $text-color;
        }
    }
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
@mixin backface-visibility($visibility) {
    -webkit-backface-visibility: $visibility;
    -moz-backface-visibility: $visibility;
    backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select;
    // IE10+
    user-select: $select;
}

// Dividers (basically an hr) within dropdowns and nav lists
@mixin nav-divider($color: $gray-lightest) {
    height: 1px;
    margin: ($line-height-computed / 2 - 1) 0;
    overflow: hidden;
    background-color: $color;
}

// WebKit-style focus
@mixin tab-focus {
    // Default
    outline: thin dotted;
    // WebKit
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

@mixin static-grid {
    width: $container-width;
    margin: 0 auto;
    &:after {
        content: " ";
        display: block;
        clear: both;
    }
}

// ie Fallback static grid will render in IE8 stylesheet if $fixed container variable exists
@mixin container-render {
    @if variable-exists(fixed-container) {
        @include static-grid;
    }
    @else {
        @include container($container-width);
    }
}

// outline helper
@mixin out($color: pink, $width: 1px) {
    outline: $width solid $color;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin float($side: left) {
    float: unquote($side);
}

// Box Shadow
@mixin box-shadow($left: 0, $top: 2px, $blur: 2px, $spread: 0, $color: #000, $inset: "") {
    -webkit-box-shadow: $left $top $blur $spread $color #{$inset};
    -moz-box-shadow: $left $top $blur $spread $color #{$inset};
    box-shadow: $left $top $blur $spread $color #{$inset};
}

// No Bullets on lists
@mixin no-bullet {
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
}

@mixin no-bullets {
    list-style: none;
    li {
        @include no-bullet;
        padding-left: 0;
        
        &:before {
            content: none;
            display: none;
        }
    }
}

@mixin squish-text {
    font: 0/0 serif;
    text-shadow: none;
    color: transparent;
}

@mixin tablefy($gutter: 10px, $vertical-align: middle, $nested: false) {
    display: table;
    width: 100%;

    & > * {
        display: table-cell;
        padding: 0 $gutter;
        width: 1%;
        vertical-align: $vertical-align;

        @if $nested {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    
        // The visibility helper sets display block, which can break tablefy, so this is the fix...
        @include breakpoint($size-1) {
            &.hidden-mobile {
                display: none;
            }
        }

        @include breakpoint($size-480) {
            &.hidden-mobile {
                display: table-cell;
            }
            &.hidden-tween {
                display: none;
            }
        }

        @include breakpoint($size-768) {
            &.hidden-tween {
                display: table-cell;
            }
            &.hidden-tablet {
                display: none;
            }
        }

        @include breakpoint($size-960) {
            &.hidden-tablet {
                display: table-cell;
            }
            &.hidden-desktop {
                display: none;
            }
        }

        @include breakpoint($size-1200) {
            &.hidden-desktop {
                display: table-cell;
            }
            &.hidden-desktop-l {
                display: none;
            }
        }
    }
}




//
// Angles
//
@mixin angle($side-x: 'left', $side-y: 'top', $angle: $mask-gradient-mobile, $width: $mask-width-mobile, $responsive: true) {
    $opposite-y: 'bottom';

    // If it passed bottom as the y, then the opposite would be top...
    @if $side-y == 'bottom' {
        $opposite-y: 'top';
    }

    // Init border arrow
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    -moz-transform: scale(.9999);

    top: auto;
    bottom: auto;
    pointer-events: none;

    // Init all colors
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    
    // Init rgba colors... Can apparently make it look smoother if using rgba instead of transparent...
    border-top-color: rgba(255,255,255,0);
    border-right-color: rgba(255,255,255,0);
    border-bottom-color: rgba(255,255,255,0);
    border-left-color: rgba(255,255,255,0);
    
    // Init all widths
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;

    // Setting color
    border-#{$side-x}-color: $white;

    // Position
    #{$side-y}: 0;
    #{$side-x}: 50%;
    
    // Size mob to tween
    margin-#{$side-x}: - ($width / 2);
    border-#{$side-x}-width: $width;
    border-#{$opposite-y}-width: $angle;

    @if $responsive {
        @include breakpoint($size-480) { // Size tween to tab
            $width: $width * 1.6;
            $angle: $angle * 1.6;
            margin-#{$side-x}: - ($width / 2);
            border-#{$side-x}-width: $width;
            border-#{$opposite-y}-width: $angle;
        }

        @include breakpoint($size-768) { // Size tab to desktop and above...
            $width: $width * 1.6;
            $angle: $angle * 1.6;
            margin-#{$side-x}: - ($width / 2);
            border-#{$side-x}-width: $width;
            border-#{$opposite-y}-width: $angle;
        }
    }

    // Setting widths... will later make adaptive

    // If we're extending outside of the box, then we'll need to use the opposite-y
    //@if $mode == 'invert' {
    //}
    /*
    @if $mode == 'extend' {
        #{$opposite-y}: 100%;
        border-#{$side-y}-width: $angle;
    }*/
}

// For explicitly setting the color...
@mixin angle-color($side-x: 'left', $color: false) {
    // Setting color
    border-#{$side-x}-color: $color;
}

// Not used because it overcomplicates things really
@mixin angle-calc-new($size-old, $size-new, $value) {
    // Pass in old screen, new screen, and value... basically, the idea is to work out the ratio difference and return a respectively scaled value...
    // 
}

@mixin angle-responsive-padding($side, $angle: $mask-gradient-mobile) {
    padding-#{$side}: $angle;

    @include breakpoint($size-480) { // Size tween to tab
        $angle: $angle * 1.6 - 1;
        padding-#{$side}: $angle;
    }

    @include breakpoint($size-768) { // Size tab to desktop and above...
        $angle: $angle * 1.6 - 1;
        padding-#{$side}: $angle;
    }
}

@mixin angle-responsive-margin($side, $angle: $mask-gradient-mobile, $mod: 0) { // Mod doesn't scale
    margin-#{$side}: - ($angle - $mod);

    @include breakpoint($size-480) { // Size tween to tab
        $angle: ($angle * 1.6) - 1;
        margin-#{$side}: - ($angle - $mod);
    }

    @include breakpoint($size-768) { // Size tab to desktop and above...
        $angle: ($angle * 1.6) - 1;
        margin-#{$side}: - ($angle - $mod);
    }
}

@mixin nest() {
    @include span(full nest); // Annoying drawback with current block class... have to set margins too
    margin-left: 0;
    margin-right: 0;

    @include breakpoint($size-1200) {
        
    }
}

@mixin paraspace() {
    margin-bottom: $spacing / 2;
    
    @include breakpoint($size-768) {
        margin-bottom: $spacing;
    }
}

@mixin centred-mobile() {
    text-align: center;

    @include breakpoint($size-480) {
        text-align: left;
    }
}

@mixin opacity($value){
    $IEValue: $value*100;
    filter: alpha(opacity=$IEValue);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
    opacity: $value;
}

@mixin border-bottom($pixel, $type, $color, $opacity: 0.3) {
    border-bottom: $pixel $type $color;
    /* The Fallback */
    border-bottom: $pixel $type rgba($color, $opacity);
}