.support-block {
    padding: $spacing-mobile;
	background-color: $gray-lighter;
	margin-bottom: $spacing;
    @include tablefy($spacing-mobile, top, true);

    @include breakpoint($size-480) {
        padding: $spacing;
    }

    .software-details {
        li {
            display: inline-block;
            padding-left: 0px;
            &:last-child {
                &:after {
                    display: none;
                }
            }
            &:before {
                display: none;
            }
            &:after {
                content: "|";
                color: $brand-primary;
                display: inline-block;
            }
        }
    }

    .custom-button {
        display: inline-block;
    }

    .image {
        min-width: 60px;
        margin-bottom: 10px;

        @include breakpoint($size-480) {
            text-align: right;
        }
    }

    .body {
        width: 100%;

        .body-inner {
            max-width: 600px;
        }

        p {
            font-weight: $weight-bold;
        }
    }
}