

ul {
    margin: 0;
    margin-bottom: $spacing;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding-left: $spacing;

        &:before {
            position: absolute;
            content: "•";
            color: $brand-primary; /* or whatever color you prefer */
            left: 0;
            //margin-right: $spacing;
        }
    }

    &.no-bullets {
        @include no-bullets;
    }

    &.inline {
        li {
            display: inline-block;

            &:first-child {
                padding-left: 0;

                &:before {
                    content: none;
                    display: none;
                }
            }

            &:before {
                margin-left: 6px;
                margin-right: 10px;
            }
        }
    }


    &.arrowed {
        li {
            @extend .icon-next;
            padding-left: 40px;

            &:before {
                @include custom-icon;
                font-size: 35px;
                margin-top: -8px;
            }
        }
    }
}

ol {
    margin: 0;
    margin-bottom: $spacing;
    padding: 0;
    list-style: none;

    li {
        counter-increment: step-counter;

        &:before {
            content: counter(step-counter);
            color: $brand-primary;
            margin-right: $spacing;
            width: 7px;
            display: inline-block;
        }
    }
}