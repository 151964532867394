//*******************************************************//
.sidebar-page {
    .content {
    	@include breakpoint($size-960) {
			@include span(8);
		}
    }
	aside {
    	@include breakpoint($size-960) {
			@include span(4);
		}

		// Feature block behaves differently in sidebar, and we want to style the recentnews the same...
		.recentnews-block, .feature-block, .related-links-block {
			@include span(full nest);
			margin-left: 0;

			@include breakpoint($size-768) {
				@include span(6);
			}

			@include breakpoint($size-960) {
				@include span(full nest);
				@include nest;
				@include tablefy(5px, top, true);
			}

			.image {
  				min-width: 50px;

  				@include breakpoint($size-480) {
  					min-width: 70px;
				}

				@include breakpoint($size-960) {
					min-width: 50px;
				}
  			}

			.body {
				width: 100%;

				.sub, p {
					margin-bottom: 10px;
					font-size: 16px;
				}

				.subbed {
					font-size: em(22px);

				    @include breakpoint($size-768) {
				        font-size: em(26px);
				    }
				}
			}
		}

		.recentnews-block {
			padding: 20px 0;
			border-top: 1px solid $gray-lighter;
			border-bottom: 1px solid $gray-lighter;

			display: none;

			@include breakpoint($size-960) {
				display: block;
			}
		}

		.feature-block {

  			
		}
	}
}