.imagequote-block {
	margin-bottom: 0;

	@include breakpoint($size-768) {
		margin-bottom: $spacing * 2 !important;
	}
	
	.image {
		text-align: center;

		@include breakpoint($size-768) {
			@include span(5);
			margin-top: 40px;
		}
	}

	.quote {
		@include centred-mobile;

		@include breakpoint($size-768) {
			@include span(6);
			@include push(1);
		}

		blockquote {
		}
	}
}