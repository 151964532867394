// Transitions
@mixin transition($transition...) {
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($transition-property...) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}

@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}

// Transformations
@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio);
    // IE9 only
    transform: scale($ratio);
}

@mixin scale($ratioX, $ratioY...) {
    -webkit-transform: scale($ratioX, $ratioY);
    -ms-transform: scale($ratioX, $ratioY);
    // IE9 only
    transform: scale($ratioX, $ratioY);
}

@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio);
    // IE9 only
    transform: scaleX($ratio);
}

@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio);
    // IE9 only
    transform: scaleY($ratio);
}

@mixin skew($x, $y) {
    -webkit-transform: skew($x, $y);
    -ms-transform: skewX($x) skewY($y);
    // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    transform: skew($x, $y);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    // IE9 only
    transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    // IE9 only
    transform: rotate($degrees);
}

@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees);
    // IE9 only
    transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees);
    // IE9 only
    transform: rotateY($degrees);
}

@mixin perspective($perspective) {
    -webkit-perspective: $perspective;
    -moz-perspective: $perspective;
    perspective: $perspective;
}

@mixin perspective-origin($perspective) {
    -webkit-perspective-origin: $perspective;
    -moz-perspective-origin: $perspective;
    perspective-origin: $perspective;
}

@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    // IE9 only
    transform-origin: $origin;
}

// Animations
@mixin animation($animation) {
    -webkit-animation: $animation;
    animation: $animation;
}

@mixin animation-name($name) {
    -webkit-animation-name: $name;
    animation-name: $name;
}

@mixin animation-duration($duration) {
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
}

@mixin animation-timing-function($timing-function) {
    -webkit-animation-timing-function: $timing-function;
    animation-timing-function: $timing-function;
}

@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
}

@mixin animation-iteration-count($iteration-count) {
    -webkit-animation-iteration-count: $iteration-count;
    animation-iteration-count: $iteration-count;
}

@mixin animation-direction($direction) {
    -webkit-animation-direction: $direction;
    animation-direction: $direction;
}