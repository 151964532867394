// This is a 50/50 heading with no buttons along the bottom...
.headingalt-block {
	@include centred-mobile;

	@include breakpoint($size-768) {
	}

	@include breakpoint($size-960) {
		@include nest;
	}

	&.block {
		margin-bottom: $spacing-mobile;
	}

    .body {
    	margin-bottom: $spacing;

    	@include breakpoint($size-960) {
			@include span(6);
		}

		p {
			font-size: em(18px);

		    @include breakpoint($size-768) {
		        font-size: em(20px);
		    }
		}
    }

    .image {
		margin-top: -30px;

    	@include breakpoint($size-960) {
			@include span(6);
		}

    	img {
			display: block;
			width: 100%;
    	}
    }
}