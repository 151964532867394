@mixin reskin($color) {
	h1, h2, h3, h4, h5, h5, h6 {
		color: $color;
	}

	a {
		color: $color;
	}

	.icon {
		color: $color;
	}

	.btn {
		background-color: $color;
		color: white;

		.icon {
			color: white;
		}
	}

	.quote-wrapped {
		&:before, &:after {
			color: $color;
		}
	}

	ul {
	    li {
	        &:before {
	        	color: $color;
	        }
	    }
	}

	.background-type {
		background-color: $color;
		color: white;

		h1, h2, h3, h4, h5, h5, h6 {
			color: white;
		}

		ul {
		    li {
		        &:before {
		        	color: white;
		        }
		    }
		}
	}
}

.product-type-highlight {
	@include reskin($brand-primary);
}

.product-type-xp {
	@include reskin($xp-color);
}

.product-type-xp-plus {
	@include reskin($xp-plus-color);
}

.product-type-ca {
	@include reskin($ca-color);
}

.product-type-pq {
	@include reskin($pq-color);
}

.product-type-pl {
	@include reskin($pl-color);
}

.product-type-ds {
	@include reskin($ds-color);
}

.product-type-sc {
	@include reskin($sc-color);
}

.product-type-el {
	@include reskin($el-color);
}

.product-type-mi {
	@include reskin($mi-color);
}

.product-type-lo {
	@include reskin($lo-color);
}

.product-type-so {
	@include reskin($so-color);
}

.product-type-po {
	@include reskin($po-color);
}

.product-type-ne {
	@include reskin($ne-color);
}

.product-type-v9 {
	@include reskin($v9-color);
}

.product-type-in {
    @include reskin($in-color);
}

.product-type-wb {
    @include reskin($wb-color);
}