//*******************************************************//
// #### Header styles that aren't layout related (these are stored in _layout.scss)
header {
    .header-main-nav {
        background-color: $gray-light;
    }

    .points {
        ul {
            font-weight: $weight-boldest;
            margin-bottom: 0;
        }
    }

    .h4 { // Quick fix, font size was too big for tablet...
		font-size: 16px;

		@include breakpoint($size-960) {
			font-size: 18px;
		}
    }
}