/**/
// # Headings

//Hide all H1

h1, h2, h3, h4, h5, h6 , .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $header-color;
    font-family: $font-family-heading;
    font-weight: $weight-thin;
    margin: 0;
    line-height: normal;
    margin-bottom: $spacing / 2;
    
    @include breakpoint($size-768) {
        margin-bottom: $spacing;
    }
}

h1, .h1 {
    font-size: em(32px);

    @include breakpoint($size-768) {
        font-size: em(45px);
    }
}

h2, .h2 {
    font-size: em(28px);
    
    @include breakpoint($size-768) {
        font-size: em(35px);
    }
}

h3, .h3 {
    font-size: em(24px);

    @include breakpoint($size-768) {
        font-size: em(30px);
    }
}

h4, .h4 {
    font-size: em(18px);

    @include breakpoint($size-768) {
        font-size: em(20px);
    }
}

h5, .h5 {
    font-size: em(16px);

    @include breakpoint($size-768) {
        font-size: em(16px);
    }
}

h6, .h6 {
    font-size: em(14px);

    @include breakpoint($size-768) {
        font-size: em(14px);
    }
}

a {
    color: $link-color;
    text-decoration: none;
    &:hover,
    &:focus {
        color: $link-hover-color;
        text-decoration: underline;
    }
    &:focus {
        @include tab-focus;
    }
}

p {
    margin-top: 0;
    @include paraspace;

    a {
        text-decoration: underline;
    }
}

.subbed {
    margin-bottom: 0;
}

.sub {
    @include paraspace;
}

.highlight {
    color: $brand-primary;
    .feature-block & {
        color: $header-color;
    }
}

.brief {
    font-size: em(20px);
    font-weight: $weight-normal;

    @include breakpoint($size-768) {
        font-size: em(22px);
    }
}

b, .bold {
    font-weight: $weight-bold;
}