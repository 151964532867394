.related-links-block {
	clear: both;
	text-align: center;
	@include span(full nest);
	margin-left: 0;
	ul {
		margin-top: 10px;
		font-size: em(16px);
		li {
			display: inline-block;
			margin-left: 10px;
		}
		@include breakpoint($size-480) {
			li {
				display: block;
				margin-left: 0px;
			}
		}
	}

	@include breakpoint($size-480) {
		text-align: left;
		@include tablefy(5px, top, true);
	}
}