.modal {
	position: fixed;
	display: none;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 999;
	cursor: pointer;
	overflow-y: auto;
	a.modal-close {
		position: absolute;
		top: -30px;
		color: $white;
		right: 0px;
		font-size: 23px;
		display: block;
		&:hover, &:active {
			text-decoration: none;
			cursor: pointer;
		}
	}
	.modal-inner {
		position: absolute;
		top: 20%;
		left: $spacing-mobile;
		right: $spacing-mobile;
		cursor: pointer;
		margin-bottom: 20px;
	}

	.modal-content {
		background-color: white;
		position: relative;
		margin: auto;
		max-width: 100%;
		padding: $spacing-mobile;
		cursor: auto;
		@include breakpoint($size-480) {
			padding: $spacing;
			max-width: 80%;

		}
		@include breakpoint($size-769) {
			max-width: 700px;
		}
	}
}

.modal-open {
	overflow: hidden;
}