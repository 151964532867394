.home-page {
	.quote-block {
		&:before {
			background-image: url('/images/background-quote.jpg');
		}
	}
	#about {
		.toggle-hidden {
			display: block;
			margin-bottom: 20px;
		}
		.hidden-content {
			display: none;
		}
	}
}

