.bodyimage-block {
    .body {
        @include centred-mobile;
    }

    .image {
        @include centred-mobile;
    }
}

.bodyimage-block {
    @include breakpoint($size-768) {
        @include nest;
    }

    .body {
        @include breakpoint($size-768) {
            @include span(7 of 12);
        }
    }

    .image {

        @include breakpoint($size-768) {
            @include span(5 of 12);
        }
    }
}