.bodylist-block {
	@include breakpoint($size-960) {
		@include nest;
	}

	.body {
		@include breakpoint($size-960) {
			@include span(6);
		}
	}

	.list {
		@include breakpoint($size-960) {
			@include span(6);
		}
	}
}