//*******************************************************//
// #### Footer styles that aren't layout related (these are stored in _layout.scss)
footer {
    background-color: $gray-lighter;

	a {
		color: $text-color;
		font-weight: $weight-bold;
		&:hover, &:active, &:focus {
            outline: 0;
            border: 0;
        }
	}

	.footer-main-site {

        .footer-links {
		    .head {
		        font-size: em(20px);
		        font-weight: $weight-bold;
				color: $text-color;
				margin-bottom: 20px;
		    }

		    .list {
				@include no-bullets;
		    }
		}
	}

	.footer-main-footnote {
		.footer-social {
			.list {
				@include no-bullets;

				.icon {
					font-size: 24px;

					@include breakpoint($size-768) {
                        font-size: 28px;
                    }

                    &.icon-twitter {
						color: $social-twitter;
                    }

                    &.icon-facebook {
						color: $social-facebook;
                    }

                    &.icon-youtube {
						color: $social-youtube;
                    }
				}

				.label {
					font-size: 14px;

					@include breakpoint($size-768) {
                        font-size: 16px;
                    }
				}
			}
		}

        .footer-copyright, .footer-terms {
            font-size: em(12px);
            margin-bottom: 3px;
            font-weight: $weight-bold;
        }
	}
}