.lists-block {
	padding-left: $spacing-mobile;
	padding-right: $spacing-mobile;

	@include breakpoint($size-768) {
		padding-left: $spacing;
		padding-right: $spacing;
	}

	.lists {
		ul {
			margin-bottom: 0;

			@include breakpoint($size-768) {
				@include span(6);
			}

			li {
				margin-bottom: 20px;
			}
		}
	}
}