.articlesnav-block {
	margin-bottom: $spacing * 2;
	@include clearfix;

	.prev-container, .next-container {
		@include span(6 nest);
		margin-top: -10px; // I like my code like I like my women, dirty
		padding: 0 10px;

		@include breakpoint($size-1200) {
			padding: 0;
		}
	}

	.prev-container {
		padding-left: 0;
		text-align: right;

		@include breakpoint($size-768) {
			text-align: left;
		}

		.icon-prev {
	        padding-left: 10px;
	    }
	}

	.next-container {
		padding-right: 0;
		text-align: left;

		@include breakpoint($size-768) {
			text-align: right;
		}

	    .icon-next {
			padding-right: 10px;
	    }
	}

	.back-container {
		.icon-prev {
			padding-right: 10px;
		}
	}

	.icon {
		width: auto;
		font-size: 40px;
	}
}