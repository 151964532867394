/*.styled-select select {
   background: transparent;
   width: 268px;
   padding: 5px;
   font-size: 16px;
   line-height: 1;
   border: 0;
   border-radius: 0;
   height: 34px;
   -webkit-appearance: none;
}

.styled-select {
   width: 240px;
   height: 34px;
   overflow: hidden;
   background: url(new_arrow.png) no-repeat right #ddd;
   border: 1px solid #ccc;
}*/

input, select, textarea {
    border: none;
    padding: 10px;
    display: inline-block;
    width: 100%;
    margin: 0.5em 0;
    @include placeholder();
}

input[type="text"], select {
    height: 40px; // Was min-height, but IE8 decides to ignore box sizing if you set min-height? Wtf IE... Wtf...
}

textarea {
    height: 9em;
}

input[type="checkbox"], input[type="file"] {
    display: inline-block;
    width: auto;
}

select {
    padding-top: 8px;
    padding-bottom: 7px;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $white;
}

.styled-select {
    position: relative;

    &:before, &:after {
        content: " ";
        position: absolute;
        pointer-events: none;
    }

    &:before {
        top: 0;
        right: 0;
        bottom: 0;
        width: 30px;
        background-color: $white;
    }

    &:after {
        top: 30px;
        margin-left: -25px;
        margin-top: -4px;
        border: solid transparent;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: $select-arrow-color;
        border-width: 8px;
        border-left-width: 4px;
        border-right-width: 4px;
    }

    select {
        &:focus {
            outline: 0;
        }
    }
}

.styled-upload {
    display: inline-block;

    .btn {
        position: relative;
        overflow: hidden;
        margin-bottom: 0;
        vertical-align: middle;
        margin-left: 5px;
        margin-right: 2px;
        margin-bottom: 6px;
    }

    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}


.input-inline {
    @include clearfix;
    //@include paraspace;
    margin-bottom: 0;

    @include breakpoint($size-480) {
        @include bleed(gutter(12));
        padding: 0;
        margin-bottom: $spacing;
    }

    > div {
        @include span(12);
        margin-bottom: 10px;

        input, select {
            @include span(12 nest);
        }

        @include breakpoint($size-480) {
            @include span(6);
            margin-bottom: 0;
        }
    }
}

.form-block {
    .input-inline > div {
        @include span(12 nest);
        margin-left: 0px;
        margin-right: 0px;
    }

    .umbraco-forms-field {
        clear: none;
    }

    #contour input.text, #contour input.title, #contour textarea, #contour select, .contour input.text, .contour input.title, .contour textarea, .contour select {
        border: 0px;
    }

    #contour fieldset, .contour fieldset {
        margin: 0px;
        padding: 0px;
    }

    .two-col-form {
        input[type="file"] {
            width: 100%;
        }

        .input-inline {
            margin-bottom: 0px;
        }

        select {
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
        }

        .umbraco-forms-field {
            margin-bottom: 20px;
            margin-left: 0px;
            margin-right: 0px;

            .field-validation-error {
                padding-left: 0px;
                padding-top: 10px;
                display: block;
            }

            @include breakpoint($size-480) {
                @include span(6);

                div > .text {
                    max-width: 100% !important;
                    width:100%;
                    margin-left: 0px;
                    margin-right: 0px;
                }

                &.titleanddescription {
                    margin-bottom: 0;
                    @include span(12);
                }
            }
        }

        .textfield {
            input {
                width: 100%;
                max-width: 100% !important;
            }
        }

        .textarea {
            @include span(12);
            margin-bottom: 20px;

            textarea {
                width: 100%;
                max-width: 100% !important;
            }
        }

        @include breakpoint($size-480) {
            .input-inline {
                margin: 0px;
            }

            .checkbox {
                @include span(12);
                margin-bottom: 20px;
                div {
                    margin-bottom: 20px;
                    min-height: 20px;
                }
            }

            .text {
                margin-bottom: 0px;
            }

            .dropdownlist {
                &.platform {
                    @include span(12);

                    select {
                        @include span(6 nest);
                    }
                }

                select {
                    width: 100%;
                }
            }

            .fileupload {
                margin-bottom: 20px;
                @include span(12);

                input {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }

            .textfield {
                &.serialno {
                    @include span(12);

                    input {
                        @include span(6 nest);
                    }
                }
            }
        }
    }
}

.support-request {
    .umbraco-forms-field {
        @include breakpoint($size-480) {
            &.title {
                @include span(12);
            }

            &.title {
                select {
                    @include span(6 nest);
                }
            }
        }
    }
}

.trialversiondownload {
    .btn.btn.primary {
        background: $brand-primary;
        color: $white;
    }
}


.trialversiondownload .contourNavigation .col-md-12::after {
    margin-left: 90px;
    position: relative;
    top: 30px;
    content: "";
    display: inline-block;
    width: 80px;
    height: 100px;
    background-repeat: no-repeat;
    background-image: url("/images/trial-version-download-small.png");
}

.cutritetrialversiondownload {
    .btn.btn.primary {
        background: $v9-color;
        color: $white;
    }
}
