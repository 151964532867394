.faq-block {
	margin-bottom: 3px;
	background-color: $gray-lighter;

	// Containers
	.head, .body {
		position: relative;

		font-size: em(18px);

	    @include breakpoint($size-768) {
	        font-size: em(20px);
	    }

		&:before {
			display: block;
			position: absolute;
			top: 0;
			left: 10px;
			@include custom-icon;
			pointer-events: none;
			font-size: 55px;
		}
	}

	.head {
		@extend .icon-question;

	}

	.body {
		@extend .icon-answer;
		&:before {
			color: $brand-primary;
		}
	}

	// Inner stuff
	.head-inner, .body-inner {
		padding: 14px 14px 14px 60px;
	}

	.head-inner {
		display: block;
		color: $text-color;

		&, &:active, &:hover {
			text-decoration: none;
		}
	}

	.body-inner {
	}
}