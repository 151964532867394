//*******************************************************//
.news-article-page {
    .headingsolo-block {
        @include nest;
    }
    #main-container {
        @include breakpoint($size-960) {
            @include span(6);
        }
    }
    #features {
        @include breakpoint($size-960) {
            @include span(6);
        }
    }
}