@import "/scripts/libs/owlCarousel/owl.carousel.css";
@import "/scripts/libs/owlCarousel/owl.transitions.css";

/*
*   Owl Carousel Owl Demo Theme 
*   v1.3.3
*/

.owl-controls {

    // Buttons
    .owl-buttons {
        display: inline-block;

        .owl-next, .owl-prev {
            color: $white;
            display: inline-block;
            zoom: 1;
            *display: inline;
            margin: 0 10px;
            width: 30px;
            line-height: 30px;
            font-size: 19px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            background: $gray;

            .icon {
                display: inline-block;
                vertical-align: middle;
                margin-top: -1px;
            }
        }

        .owl-next {
            .icon {
                margin-left: 2px;
            }
        }
    }
    &.clickable .owl-buttons div:hover {
        text-decoration: none;
    }
    .owl-pagination {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: -2px;

        // Pagination
        .owl-page {
            display: inline-block;
            zoom: 1;
            *display: inline;
            span {
                display: block;
                width: 14px;
                height: 14px;
                margin: 5px 4px;

                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                border-radius: 20px;
                background: $white;
            }
            &.active span {
                background: $brand-primary;
            }
        }
    }
    &.clickable .owl-pagination .owl-page:hover span {
        
    }

    // Numbers
    .owl-page span.owl-numbers {
        height: auto;
        width: auto;
        color: #FFF;
        padding: 2px 10px;
        font-size: 12px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
    }
}

.owl-item {
    &.loading {
        min-height: 150px;
        background: url(/scripts/libs/owlCarousel/AjaxLoader.gif) no-repeat center center;
    }
}

.owl-carousel {
    
}