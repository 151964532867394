.playlistcarousel-block {
	.tab-content {
		background-color: $gray-lighter;

		.tab-pane {
			position: relative;
		}
	}

	.owl-carousel {
		padding: 10px 60px;

		.item {

			a {
				display: block;
				padding: 10px 10px;
				color: $text-color;

				&:hover, &:active, &:focus, &:visited {
					text-decoration: none;
					outline: 0;
				}

				&:hover {
					color: $brand-primary;
				}

				&.active {
					background-color: $brand-primary;
					color: white;
				}
			}

			.caption {
				text-align: center;
				margin: 5px;
				font-size: em(20px);
			}
		}

		.owl-controls {
			height: 0;

			.owl-prev, .owl-next {
				position: absolute;
				top: 50%;
				margin-top: -15px;
				text-align: center;
			}

			.owl-prev {
				left: 10px;
			}

			.owl-next {
				right: 10px;
			}
		}
	}
}