//*******************************************************//
//* HEADER *//

// #### Page and container layout properties
html, body { // Stop scrolling when mobile menu active...
    width: 100%; // This is needed to get overflow hidden working for mobile devices... however, if I change this in CSS, some devices hide random bits of content, until you rotate the device or something, weird!
    height: 100%;
    &.menu-down {
        overflow: hidden;

        @include breakpoint($size-768) {
            overflow: auto;
        }
    }
}

header {
    //padding: 10px 10px 0 10px;
    @include breakpoint($size-768) {
        padding: 0;
    }

    .contain {
        @include breakpoint($size-768) {
            @include container-render;
        }
    }
    .header-main-info {
        @include tablefy;
        padding: 20px 0;

        @include breakpoint($size-768) {
            padding: $spacing 0;
        }

        & > * {
            vertical-align: middle;
        }

        .logo {
            img {
                width: 200px;
                max-width: none;
                
                @include breakpoint($size-768) {
                    width: 230px;
                }

                @include breakpoint($size-960) {
                    width: auto;
                }
            }
        }

        .points, .contact {
            display: none;
            white-space: nowrap;

            @include breakpoint($size-768) {
                display: table-cell;
            }
        }

        .points {
            width: 100%;
        }

        .contact {
            text-align: right;
        }
    }
    .header-main-nav {
        z-index: 999;

        @include breakpoint($size-768) {

            .sf-menu {
                @include tablefy(0);
            }

        }

        @include breakpoint($size-1200) {
            @include span(full);
        }
    }
}

//*******************************************************//
//* FOOTER *//
footer {
    @include span(full nest);

    .contain {
        position: relative;
        padding: 40px 0;
        padding-top: 0;

        @include breakpoint($size-768) {
            padding-top: 40px;
        }

        //@include breakpoint($size-768) {
            @include container-render;
        //}

        /*.footer-nav {
            padding: 0 10px;
        }*/
    }

    .footer-main-site {
        display: none;
        @include span(full nest);

        @include breakpoint($size-768) {
            display: block;
        }

        @include breakpoint($size-960) {
            @include span(9 nest);
            //@include bleed(gutter(12));
        }

        .footer-links {
            @include span(full);

            @include breakpoint($size-768) {
                @include span(3 of 9);
                //@include gallery(3 of 9); 
                //@include bleed(gutter(9));
            }

            &:last-child {
                //@include last;
            }

            .list {
                .item {
                    margin-bottom: 15px;
                    line-height: 26px;
                    a {
                        display: table;
                        width: 100%;
                    }
                    span {
                        display: table-cell;
                        text-align: left;
                    }
                }

                .icon-holder {
                    padding-right: 20px;
                    width: 25px;
                    img {
                        width: 25px;
                        max-width: 25px;
                    }
                }
            }
        }
    }

    .footer-main-footnote {
        @include span(full nest);
        text-align: center;

        @include breakpoint($size-768) {
            text-align: left;
        }

        @include breakpoint($size-960) {
            @include span(3);
        }

        .footer-social {
            @include span(full);
            .head {
                display: none;
                @include breakpoint($size-768) {
                    display: block;
                    margin-top: 0px;
                    font-size: em(20px);
                    font-weight: $weight-bold;
                    color: $text-color;
                    margin-bottom: 20px;
                }
            }
            @include breakpoint($size-768) {
                @include span(8);
            }

            @include breakpoint($size-960) {
                @include span(full);
            }

            &.below {
                display: none;

                @include breakpoint($size-768) {
                    display: block;
                }

                @include breakpoint($size-960) {
                    display: none;
                }
            }

            &.above {
                display: block;

                @include breakpoint($size-768) {
                    display: none;
                }

                @include breakpoint($size-960) {
                    display: block;
                }
            }

            .list {
                margin-bottom: 10px;
                margin-top: 20px;
                @include breakpoint($size-960) {
                    margin-top: 0px;
                    margin-bottom: 40px;
                }

                .item {
                    margin: 0 5px;
                    margin-bottom: 5px;
                    display: inline-block;
                    @include breakpoint($size-768) {
                        margin-right: 25px;
                    }

                    @include breakpoint($size-960) {
                        display: block;
                        margin: 0;
                        margin-bottom: 10px;    
                    }
                }

                .icon {
                    margin-right: 4px;

                    @include breakpoint($size-768) {
                        margin-right: 15px;
                    }
                }

                .label, .icon {
                    vertical-align: middle;
                    display: inline-block;
                }
            }
        }

        .footer-info {
            @include span(full);

            @include breakpoint($size-768) {
                @include span(4);
            }

            @include breakpoint($size-960) {
                @include span(full);
            }

            .footer-logo {
                margin-bottom: 8px;

                img {
                    width: 200px;
                }
            }
        }
    }
}