﻿.form-block {
    .contourPage {
        padding: $spacing-mobile;
        background-color: $gray-lighter;
        margin-bottom: $spacing;

        @include breakpoint($size-480) {
            padding: $spacing;
        }

        .span6.col-md-6 {
            /*width: 50%;
            float: left;*/
            .styled-select::before {
                width: initial;
            }
        }

        .span12.col-md-12 {
            .fieldLabel {
                font-weight: inherit;
            }
        }

        .checkbox input {
            float: left;
            margin-top: $spacing / 5;
        }

        .umbraco-forms-field.checkbox div {
            width: 5%;
        }

        .umbraco-forms-field.checkbox label {
            float: right;
            width: 95%;
        }
    }
}
