.download-block {
	background-color: $gray-lighter;
	padding: $spacing;
	@include centred-mobile;

	form {
		
	}

	p {
		font-size: em(18px);

	    @include breakpoint($size-768) {
	        font-size: em(20px);
	    }
	}
}