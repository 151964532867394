[class^="icon-"], [class*=" icon-"] {
    font-family: 'Magi-cut';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin custom-icon {
    font-family: 'Magi-cut';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-swipe:before {
    content: "\41";
}

.icon-youtube:before {
    content: "\79";
}

.icon-question:before {
    content: "\71";
}

.icon-answer:before {
    content: "\61";
}

.icon-next:before {
    content: "\52";
}

.icon-prev:before {
    content: "\4c";
}

.icon-checkmark:before {
    content: "\63";
}

.icon-arrow-left:before {
    content: "\6c";
}

.icon-arrow-down:before {
    content: "\64";
}

.icon-arrow-up:before {
    content: "\75";
}

.icon-arrow-right:before {
    content: "\72";
}

.icon-twitter:before {
    content: "\74";
}

.icon-facebook:before {
    content: "\66";
}

.icon-search:before {
    content: "\73";
}

/*.icon-twitter:before {
    content: "\74";
}

.icon-facebook:before {
    content: "\66";
}*/

.icon-search:before {
    content: "\73";
}

.icon-close:before {
    content: "\78";
}

.icon-bars:before {
    content: "\6e";
}

.icon-file-pdf-o:before {
    content: "\46";
}

.icon-close:before {
    content: "\78";
}

.icon-bars:before {
    content: "\6e";
}

.icon-file-pdf-o:before {
    content: "\46";
}

.icon-display:before {
    content: "\e956";
}

.icon-video-camera:before {
    content: "\e900";
}

.icon-credit-card:before {
    content: "\e902";
}
