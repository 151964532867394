

// In order to use masks as extensions, will need to use separate elements, because overflow hidden is needed to hide the masks off screen really :/

//
// Base classes
//
.masked, .masked-top, .masked-bottom {
	position: relative;
	overflow: hidden;
	z-index: 1;
}
.mask-overlay { // If we have a mask going over something, let's make sure it's not clickable...
	pointer-events: none;
}

.masked-top {
	@include angle-responsive-padding(top);
}
.masked-top-overlay {
	@include angle-responsive-margin(top);
}

.masked-bottom {
	@include angle-responsive-padding(bottom);
}
.masked-bottom-overlay {
	@include angle-responsive-margin(bottom);
}


//
// Corners
//
.mask-top-left {
	&.mask-invert:before, &:before {
		@include angle(left, top);
	}
	&.mask-gray:before {
		@include angle-color(left, $gray-light);
	}
}
.mask-top-right {
	&.mask-invert:before, &:before {
		@include angle(right, top);
	}
	&.mask-gray:before {
		@include angle-color(right, $gray-light);
	}
}
.mask-bottom-left {
	&.mask-invert:after, &:after {
		@include angle(left, bottom);
	}
	&.mask-gray:after {
		@include angle-color(left, $gray-light);
	}
}
.mask-bottom-right {
	&.mask-invert:after, &:after {
		@include angle(right, bottom);
	}
	&.mask-gray:after {
		@include angle-color(right, $gray-light);
	}
}



//
// Archived... other solutions, which may need to be used later, and it's easier to comment out than dig through Git
//

$mask-gradient: 100px;
$pi: 3.142;

$degree-desktop: 3;
/*

// For all the angles used...

.mask-tr {
	&:before {
		content: " ";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 50px;
		background-image: url('../images/mask-top-right.png');
		background-size: 100% 100%;
		background-position: center center;
		//image-rendering: crisp-edges;
		//image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
	}
}


// Basically, work out ratio of degree to a circle (so, 36 degrees then 10% of a circle)
// Then, calc the circumference
// Then combine to get the height the mask should be by the degrees of a circle it has moved...
@function pi-distance($degree, $width: 1200px) {
	@return ($width * $pi) * (($degree * 2) / 360) + 1; // +1 for rounding purposes
}

%skew {
	content: " ";
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	background: $white;
	overflow: visible;
}


.masked-white {
	&:after {
		background-color: $white;
	}

	&:before {
		background-color: $white;
	}
}

.mask-top-right {
	&:before {
		@extend %skew;
		top: 0;
		height: pi-distance($degree-desktop);
		//margin-top: - pi-distance($degree-desktop) / 2;
		-webkit-transform: skewY(#{$degree-desktop}deg);
		-moz-transform: skewY(#{$degree-desktop}deg);
		-ms-transform: skewY(#{$degree-desktop}deg);
		-o-transform: skewY(#{$degree-desktop}deg);
		transform: skewY(#{$degree-desktop}deg);
		-webkit-transform-origin: 100% 0%;
		-ms-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
}

.mask-top-left {
	&:before {
		@extend %skew;
		top: 0;
		height: pi-distance($degree-desktop);
		//margin-top: - pi-distance($degree-desktop) / 2;
		-webkit-transform: skewY(-#{$degree-desktop}deg);
		-moz-transform: skewY(-#{$degree-desktop}deg);
		-ms-transform: skewY(-#{$degree-desktop}deg);
		-o-transform: skewY(-#{$degree-desktop}deg);
		transform: skewY(-#{$degree-desktop}deg);
		-webkit-transform-origin: 0% 0%;
		-ms-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
	}
}

.mask-bottom-right {
	&:after {
		@extend %skew;
		bottom: 0;
		height: pi-distance($degree-desktop);
		//margin-bottom: - pi-distance($degree-desktop) / 2;
		-webkit-transform: skewY(-#{$degree-desktop}deg);
		-moz-transform: skewY(-#{$degree-desktop}deg);
		-ms-transform: skewY(-#{$degree-desktop}deg);
		-o-transform: skewY(-#{$degree-desktop}deg);
		transform: skewY(-#{$degree-desktop}deg);
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}
}

.mask-bottom-left {
	&:after {
		@extend %skew;
		bottom: 0;
		height: pi-distance($degree-desktop);
		//margin-bottom: - pi-distance($degree-desktop) / 2;
		-webkit-transform: skewY(#{$degree-desktop}deg);
		-moz-transform: skewY(#{$degree-desktop}deg);
		-ms-transform: skewY(#{$degree-desktop}deg);
		-o-transform: skewY(#{$degree-desktop}deg);
		transform: skewY(#{$degree-desktop}deg);
		-webkit-transform-origin: 0% 100%;
		-ms-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
	}
}

*/