// # Placeholders
// --------------------------------------------------



%hide-text {
    display: inline-block;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

%read-more {
    font-weight: bold;
    display: inline-block;
    clear: both;
    border-bottom: 1px solid $white;
    &:after {
        content: "\e803";
        //font-family: $font-icons;
        font-size: 0.8em;
        padding-left: 5px;
    }
    &:hover {
        text-decoration: none;
        border-bottom: 1px solid $brand-primary;
        &:after {
            border: none;
        }
    }
    span {
        @extend %sr-only;
    }
}

%sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;
}

%remove-before {
    &:before {
        content: none;
        display: none;
    }
}