.banner-block {

	.banner-block-inner {
		padding: 15px $spacing;
		border: 4px solid $gray-light;
   		background-color: $white;
   		@include centred-mobile;

   		@include breakpoint($size-768) {
			@include tablefy($spacing-mobile, middle, true);
   		}
	}

	.banner-image {
		img {
			min-width: 65px;
		}
	}

	.banner-body {
		width: 100%;

		p {
			margin-bottom: 5px;
		}
	}

	.banner-button {
		white-space: nowrap;
	}
}