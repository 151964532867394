@import "helpers/bootstrap sass and mixins/variables";
@import "helpers/bootstrap sass and mixins/navs";
@import "helpers/bootstrap sass and mixins/mixins/tab-focus";

.videopicker-block {
	@include breakpoint($size-960) {
		@include nest;
	}

	.video {
		margin-bottom: $spacing;

		@include breakpoint($size-960) {
			@include span(8);
			margin-bottom: 0;
		}

		iframe {
			width: 100%;
		}
	}

	.picker {
		@include breakpoint($size-960) {
			@include span(4);
		}

		.toggleplaylist {
			cursor: pointer;
			padding: 5px;
			position: relative;
			//padding-left: 35px;
			@extend .icon-close;

			&:before {
				@include custom-icon;
				display: inline-block;
				width: 35px;
				margin-top: -5px;
  				vertical-align: middle;
				//position: absolute;
				//left: 5px;
			}

			&.collapsed {
				@extend .icon-bars;
			}
		}

		li {
			a {
				display: block;
				padding: 10px;
				color: $text-color;
				font-size: em(24px);
			}

			img {
				display: inline-block;
				margin-right: 15px;
				width: 30px;
			}

			&.active {
				a {
					background-color: $gray-lighter;
				}
			}

			&.active, &:hover {
				a {
					color: $brand-primary;
					text-decoration: none;
				}
			}
		}
	}
}