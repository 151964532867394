.listimage-block {
	@include clearfix;
	@include centred-mobile;

	.list {
		@include breakpoint($size-480) {
			@include span(6);
		}

		ul {
			display: inline-block;
			text-align: left;
		}
	}

	.image {
		text-align: right;

		@include breakpoint($size-480) {
			@include span(6);
		}
	}
}