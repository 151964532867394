.heading-block {
	@include centred-mobile;
	//padding-left: 10px;
	//padding-right: 10px;

	@include breakpoint($size-768) {
		//@include nest;
		padding: 0;
		@include tablefy(10px, top, true);
		width: auto; // Fixed a bug that just came out of nowhere? weird...
	}

	.toggle-hidden {
		display: block;
		margin-bottom: 20px;
	}

	.hidden-content {
		display: none;
	}

	.promo-text {
		text-align: center;
		display: inline-block;
		padding-top: 10px;
		@include breakpoint($size-768) {
			font-size: em(24px);
			text-align: right;
			line-height: 1em;
			float: right;
			width: 70%;
		}
	}

    .body {
    	width: 100%;

    	@include breakpoint($size-768) {
			//@include span(7);
		}

		p {
			font-size: em(18px);

		    @include breakpoint($size-768) {
		        font-size: em(20px);
		    }
		}
    }

    .image {
    	@include breakpoint($size-768) {
			//@include span(5);
			margin-top: 70px;
    		min-width: 300px;
		}

		@include breakpoint($size-769) {
			//@include span(5);
    		min-width: 490px;
		}

		@include breakpoint($size-960) {
			margin-top: 0;
		}

    	img, object {
			display: block;
			width: 100%;
    	}

    	img {
			margin-top: $spacing;
    	}
    }

    .icons {
    	a {
    		&:focus {
    			outline: 0;
    		}
    	}
    	li {
    		margin-right: 5px;
    	}
		img {
			//width: 22px; // Was breaking IE? weird
			height: 22px;
		}
    }

    .actions, .moreinfo, .pdfs {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
    }

	.link-iconned {
		margin-right: 25px;
	}

	.btn {
		margin-right: 10px;
	}

	.moreinfo, .pdfs {
		a {
			display: inline-block;
			width: 48%;
			margin-right: 0px;
			padding-right: 25px;
			position: relative;
			padding-left: 35px;
			text-align: left;
			vertical-align: top;

			.icon {
				position: absolute;
				left: 0px;
				top: 0px;
			}
			@include breakpoint($size-769) {
				display: block;
				width: 100%;
				padding-left: 0px;
				margin-bottom: 10px;
				.icon {
					position: relative;
				}
			}
			@include breakpoint($size-960) { 
				width: auto;
				display: inline;
				margin-right: 25px;
				text-align: center;
				padding-left: 35px;
				padding-right: 0px;
				&:last-child {
					margin-right: 0px;
				}
				.icon {
					position: absolute;
					left: 0px;
					top: -3px;
				}
			}

		}
	}

	.vimeo-video {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;
		margin-top: 20px;
		@include breakpoint($size-769) {
			margin-top: 0px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

}