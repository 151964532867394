


// Trying to make it work with the breakpoint thing...
@include breakpoint($size-1) {
    .hidden-mobile {
        display: none;
    }
}

@include breakpoint($size-480) {
    .hidden-mobile {
        display: block;
    }
    .hidden-tween {
        display: none;
    }
}

@include breakpoint($size-768) {
    .hidden-tween {
        display: block;
    }
    .hidden-tablet {
        display: none;
    }
}

@include breakpoint($size-960) {
    .hidden-tablet {
        display: block;
    }
    .hidden-desktop {
        display: none;
    }
}

@include breakpoint($size-1200) {
    .hidden-desktop {
        display: block;
    }
    .hidden-desktop-l {
        display: none;
    }
}
