$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// # Variables
// --------------------------------------------------

// # GRID :: A Fluid Grid based on 960gs
//

//$grid: 1200px;

$container-width: 1200px;
$total-columns: 12;
$column-width: 80px;
$gutter-width: 1 / 4;
$global-box-sizing: border-box;
$gutter-position: split;
$container-style: fluid;

$susy: (columns: $total-columns, math: $container-style, gutters: $gutter-width, container: $container-width, column-width: $column-width, global-box-sizing: $global-box-sizing, gutter-position: $gutter-position);

$size-1: 1px, "no-query" true;
$size-480: 480px, "no-query" true;
$size-550: 550px, "no-query" true;
$size-768: 768px, "no-query" true; // Applying for tablet and above, instead of above tablet...
$size-769: 769px, "no-query" true;
$size-960: 960px, "no-query" true;
$size-1200: 1200px, "no-query" true;

// # Typography
$font-family-base: "museo-sans", Tahoma, Segoe, sans-serif !default;
$font-family-heading: "museo-sans", Tahoma, Segoe, sans-serif !default;

//$font-icons: "icons";
$base-font-size: 16px;
$base-line-height: 1.428;

// The font size set on the root html element.
//$base-line-height: 24px;

// #### Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor($base-font-size * $base-line-height) !default;

// This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 3 * $base-font-size;
$h2-font-size: 2.5 * $base-font-size;
$h3-font-size: 2 * $base-font-size;
$h4-font-size: 1 * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// Weights
$weight-thin: 100;
$weight-normal: 300;
$weight-bold: 500;
$weight-boldest: 700;

// # Colors
// --------------------------------------------------

$black: black;
$white: white;

// ###### Gray

//#### #222
$gray-darker: lighten(black, 13.5%) !default;

// #### #333
$gray-dark: #3c3c3c !default;

// #### #666
$gray: #5d5d5d !default;

$gray-lightish: #bdbdbd; // Just found another shade of gray which sadly can't really be skipped :P

// #### #d9d9d9
$gray-light: #dadada !default;

// #### #c2c2c2
$gray-lighter: #ececeb !default;

// #### #ededed
$gray-lightest: lighten(black, 93%) !default;

// ###### Brand

$brand-primary: #d74f4f; // Dark red
$brand-lightest-extreme: lighten($brand-primary, 60%) !default;

/*
#dadada // Light gray (nav)
#5d5d5d // Gray (header)
#3c3c3c // Dark gray (body)

#cecece // Gray (border)
#ececeb // Gray (footer)
#e2e2e2 // Gray (button)
#909090 // Gray (breadcrumb)
#767676 // Gray (carousel button)
#3e3e3e // Gray (help icon color)
#4f4f4f // Gray (menu)
*/


// ###### States
$success: #5cb85c !default;
$info: #5bc0de !default;
$warning: #b12140 !default;
$danger: #d9534f !default;

// ###### Social
$social-twitter: #48a2d7;
$social-facebook: #385591;
$social-youtube: #f04847;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $gray-lightest !default;

//** Global text color on `<body>`.
$header-color: $gray !default;

//** Global text color on `<body>`.
$text-color: $gray-dark !default;

//** Global textual link color.
$link-color: $brand-primary !default;

//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%) !default;

// #### Horizontal line color.
$hr-border: $gray-lighter !default;

// Products and modules...
$table-border-color: #636363;

$xp-color: #e90000;
$xp-plus-color: #c7c935;
$ca-color: #99cc00;
$pq-color: #e05a00;
$pl-color: #c80000;
$ds-color: #3366cb;
$sc-color: #006496;
$el-color: #006565;
$mi-color: #00bb00;
$lo-color: #6400c7;
$so-color: #633495;
$po-color: #5238c6;
$ne-color: #f96400;
$v9-color: #417cb7;
$in-color: #4343fd;
$wb-color: #0e8d33;

$product-background: #ececec;
$product-alt-background: #dedede;
$outerbackground-color: #DADADA;
$recentnews-date-color: #939393; // Sigh, so many grays...
$select-arrow-color: #c7c7c7; // Just another gray
$input-color-placeholder: #9b9b9b; // Yay...

//
// # General
//

// #### border radius
$border-radius-base: 5px !default;
$border-radius-large: 10px !default;
$border-radius-small: 3px !default;

// ### Spacing
$spacing: 25px;
$spacing-mobile: 10px;
$indent-amount: 30px; // The amount lists, blockquotes and comments are indented.


// ### Masking
$mask-gradient-mobile: 50px;
$mask-width-mobile: 480px;