.article-block {
	@include tablefy(10px, top, true);
	@include nest;
	padding-bottom: $spacing;
	border-bottom: 1px solid $gray-lighter;

	@include breakpoint($size-1200) {
		//@include tablefy(10px, top, true);
	}

	&.block {
		margin-bottom: $spacing + 10;
	}

	.body {
		width: 100%;
		@include centred-mobile;

		h2, p {
			margin-bottom: 5px;
		}

		.link-iconned {
			margin-top: 5px;
			display: inline-block;
		}

		h2 {
			a {
				color: $text-color;

				&:hover {
					text-decoration: none;
					color: $brand-primary;
				}
			}
		}
	}

	.image {
		min-width: 310px;

		@include breakpoint($size-960) {
			padding-right: 30px;
		}

		img {
			width: 100%;
			border: 1px solid $gray-light;
		}
	}


}