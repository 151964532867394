.purchasesupport-block {
    padding: $spacing-mobile;
    background-color: $gray-lighter;
    margin-bottom: $spacing;
    width: 100%;
    form {
        display: none;
        @include breakpoint($size-769) {
            display: block;
        }
    }
    .mob-message {
        display: block; 
        padding: 10px;
        h3 {
            margin-bottom: 0px;
        }
        @include breakpoint($size-769) {
            display: none;
        }
    }
    #DetailsForm {
        table {
            margin-bottom: 30px;
            td {
                padding-right: 30px;
            }
        }
    }
    .styled-select {
        position: relative;

        &:before, &:after {
            content: " ";
            position: absolute;
            pointer-events: none;
        }

        &:before {
            top: 0;
            right: 0;
            bottom: 0;
            width: 30px;
            background-color: $white;
        }

        &:after {
            margin-left: -25px;
            margin-top: -4px;
            border: solid transparent;
            border-color: rgba(136, 183, 213, 0);
            border-top-color: $select-arrow-color;
            border-width: 8px;
            border-left-width: 4px;
            border-right-width: 4px;
        }

        select {
            &:focus {
                outline: 0;
            }
        }
    }


    @include breakpoint($size-480) {
        padding: $spacing;
    }

    .versions {
        margin-bottom: 40px;

        .version {
            h4 {
                margin-bottom: 10px;
            }

            .table {
                th, td {
                    padding: 8px 5px;

                    @include breakpoint($size-480) {
                        padding: 8px 20px;
                    }

                    &:first-child {
                        padding-left: 0;
                        width: 60%;

                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }

                th {
                    border-bottom: none;
                    white-space: nowrap;
                    color: $brand-primary;
                }

                td {
                    border-color: $white;
                }

                tr:last-child td {
                    border-bottom: 1px solid $white;
                }

                input {
                    margin-left: 5px;
                }

                .code {
                    color: $brand-primary;
                    min-width: 35px;
                    display: inline-block;
                }

                .cost {
                    text-align: right;
                }
            }
        }
    }

    .summary {
        @include paraspace;

        .cost {
            @include tablefy(10px, top, true);
            font-size: em(20px);
            @include paraspace;

            .label {
                min-width: 170px;
                color: $brand-primary;
            }

            .value {
                width: 100%;
            }
        }
    }
}
