.foot-nav {
    width: 50%;
    margin: 5px 10px 20px 10px;
    float: left;
    a {
        display: block;
        text-align: left;
        margin-bottom: 10px;
    }
    @include breakpoint($size-769) {
        position: absolute;
        left: 10px;
        bottom: 20px;
        margin: 0;
        a {
            display: inline-block;
            margin-right: 20px;
        }
    }
}