.feature-block {
    margin-bottom: 40px;
    @include centred-mobile;

    @include breakpoint($size-480) {
        @include tablefy($spacing-mobile, top);
    }

    .image {
        min-width: 110px;
        margin-bottom: 10px;

        @include breakpoint($size-480) {
            text-align: right;
        }
    }

    .body {
        width: 100%;

        p {
            font-weight: $weight-bold;
        }
    }
}