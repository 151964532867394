//*******************************************************//
//* HomePage *//
//*******************************************************//
#trial {
    display: none;
    position: absolute;
    left: $spacing;
    right: $spacing;
    top: $spacing;
    z-index: 1;
    width: auto;

    .banner-block-inner {
        border: none;
        width: auto;
    }

    .banner-body {
        padding-right: 30px;
        font-size: 14px;
    }

    @include breakpoint($size-960) {
        display: block;
    }
}

#hero {
}

#features {
}

.home-page {
    position: relative;
    margin-top: 0;

    @include clearfix;
}