// # Images
// --------------------------------------------------

// # Image Mixins

// #### Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
    display: $display;
    max-width: 100%;
    // Part 1: Set a maximum relative to the parent
    height: auto;
    // Part 2: Scale the height according to the width, otherwise you get stretching
}

// #### Perfect circle
// Includes PIE fix for IE8
@mixin img-circle {
    border-radius: 50%;
    // .lt-1e9 & {
    //     @include pie;
    // }
}