.purchase-support {
	.image{
        float: right;
        margin-left: 10px;
	}
	.wysiwyg {
		@include span(12);
		margin-bottom: 0px;
	}
	#heading {
		margin-bottom: 0px;
	}
	.sidebar-page {
		clear: both;
	}
	#main-container { clear: both; }
}