.features-block {
	@include breakpoint($size-480) {
		@include nest;
	}

	.feature-block {

		@include breakpoint($size-480) {
			@include span(10);
			@include push(1 or 12);
		}

		@include breakpoint($size-769) {
			@include span(6);
		}
	}
}