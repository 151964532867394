// Mobile buttons...
.header-main-nav {
    .btn-menu, .btn-close {
        position: absolute;
        display: inline-block;
        font-size: em(30px);
        //padding: $spacing 28px;
        padding: 23px 19px 23px 24px;
        right: 0px;
        top: 0px;
        bottom: 0;
        line-height: 0;
        @include transition(background-color 0.5s);

        &:hover, &:active, &:focus {
            text-decoration: none;
        }

        @include breakpoint($size-768) {
            display: none !important; // Never show, even when menu-down active...
        }

        .icon-menu, .icon-close {
            line-height: 25px;
        }
    }

    .btn-menu {
        color: #504f57;
        background-color: $gray-light;
        //border: 1px solid #504f57;
    }

    .btn-close {
        color: $white;
        //border: 1px solid $white;
        z-index: 999;
        display: none;

        &.menu-down {
            display: block;
        }

        &:hover {
            background-color: $white;
            color: $black;
        }
    }
}

// Mobile popup menu
.menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //width: 100%;
    height: 100%;
    display: none;
    z-index: 100;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-y: auto;
    
    @include alpha-attribute("background-color", rgba($black, 0.9), white);

    @include breakpoint($size-768) {
        position: static;
        width: auto;
        height: auto;
        display: block;
        background-color: transparent;
        padding: 0;
        overflow-y: visible;
    }
}

.main-nav {
    @include breakpoint($size-768) {
        &.sticky {
            position: fixed;
            top: 0;
            left: -1px;
            right: 0;
            height: 0;
            /* center all inline content */
            text-align: center;
            z-index: 999;
            
            > .header-main-nav {
                @include border-bottom(1px, solid, #9f9b96, 0.7);
                display: inline-block;
                text-align: left;
                max-width: 1180px;
                float: none;
                width: 100%;
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    }
}

// Main nav
.header-main {
    position: relative;
    border-bottom: 1px solid $gray-light;


    @include breakpoint($size-768) {
        border-bottom: none;
    }

    .header-main-nav {
        font-family: $font-family-heading;

        @include breakpoint($size-768) {
            position: relative;
            text-align: left;
        }

        a {
            text-decoration: none;
        }

        ul {
            padding: 15px 0 0;
            margin: 0px;
            //border-top: 1px solid #404040;
            @include no-bullets;

            @include breakpoint($size-768) {
                padding-top: 0px;
                border-top: 0px;
            }

            // first level
            li.sfHover {
                color: $brand-primary;

                .sf-with-ul {
                    position: relative;
                    border-bottom: 3px solid $gray-lighter;
                    margin-bottom: -3px;
                    background-color: $gray-lighter;
                    z-index: 1;
                }
            }
            li {
                white-space: nowrap;
                text-align: center;
                padding: 0;

                @include breakpoint($size-768) {
                }

                &.selected {
                    position: relative;

                    > a {
                        //text-decoration: underline;
                        color: $link-color;

                        @include breakpoint($size-768) {
                            text-decoration: none;
                            color: inherit;
                        }
                    }

                    // Arrow above the item...
                    &:after {
                        content: none;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        margin-left: -10px;
                        pointer-events: none;
                        border: solid transparent;
                        border-color: rgba(255, 255, 255, 0);
                        border-top-color: #ffffff;
                        border-width: 10px;

                        @include breakpoint($size-768) {
                            content: " ";
                        }
                    }
                }

                &.mobile-link {
                    display: block;

                    a {
                        font-weight: normal;
                        font-size: em(14px);
                    }

                    @include breakpoint($size-768) {
                        display: none;
                    }
                }
                > a {
                    padding: 15px 10px;
                    display: block;
                    font-weight: $weight-normal;
                    font-size: 24px;
                    color: $white;

                    &:hover {
                        //text-decoration: underline;
                        color: $link-color;
                    }

                    @include breakpoint($size-768) {
                        color: $text-color;
                        font-size: 18px;
                        font-weight: $weight-bold;
                    }
                }
                ul {
                    // second level
                    display: none;

                    @include breakpoint($size-768) {
                        display: none;
                        position: absolute;
                        overflow: hidden;
                        border: 3px solid $gray-light;
                        margin-left: -3px;
                        background-color: $gray-lighter;
                        padding: 20px;

                        li {
                            text-align: left;
                            float: none;
                            padding: 0px;
                            width: 100%;

                            span {
                                &.icon-container {
                                    width: 25px;
                                    padding-right: 15px;
                                }
                                display: table-cell;
                                vertical-align: top;
                            }

                            .icon {
                                width: 25px;
                                max-width: 25px;
                                display: inline-block;
                                margin-top: -2px;
                            }

                            a {
                                min-width: 200px;
                                width: 200px;
                                display: table;
                                text-decoration: none;
                                padding: 7px;
                                font-weight: $weight-normal;
                            }

                            &:last-child {
                                a {
                                    border-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}