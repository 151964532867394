.herocarousel-block {
	margin-bottom: 20px;

    .owl-theme .owl-controls {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        @include angle-responsive-margin(top, $mask-gradient-mobile, -30);
    }

    .owl-item {
        position: relative;
        overflow: hidden;
    }

    .item {
        background-size: cover;
        //-ms-behavior: url(/scripts/polyfills/background-size/backgroundsize.min.htc); // Doesn't work until resize? I even tried manually firing resize event in js file, but didn't work... just require a large image and done
        background-position: center center;
        background-repeat: no-repeat;

        .caption-container {
            position: relative;
            padding-top: 50px;
            padding-bottom: 110px;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;

            @include breakpoint($size-960) {
                padding-top: 170px;
            }

            .caption {
                position: relative;
                display: inline-block;
                max-width: 610px;

                .mask-top-left:before {
                    @include angle(left, top, 55px, 630px, false);
                }

                .mask-bottom-left:after {
                    @include angle(left, bottom, 55px, 630px, false);
                }

                .underlayer {
                    position: absolute;
                    left: -10px;
                    right: -10px;
                    
                    // Has the angle, but needs to be 10px out, so we're using a border then shifting the whole thing...
                    &.mask-bottom-left {
                        border-bottom: 10px solid #fff;
                        border-bottom: 10px solid rgba(255,255,255,0.6);
                        margin-top: -10px;
                    }

                    &.mask-top-left {
                        border-top: 10px solid #fff;
                        border-top: 10px solid rgba(255,255,255,0.6);
                        margin-bottom: -10px;
                    }

                    &:after, &:before {
                        @include angle-color(left, rgba(255,255,255,0.6));
                    }
                }

                .caption-content {
                    background-color: white;
                    text-align: left;
                    padding: 0 20px;
                    position: relative;

                    @include tablefy(0);

                    &:before {
                        content: " ";
                        position: absolute;
                        top: 0;
                        right: -10px;
                        bottom: 0;
                        left: -10px;
                        background-color: #fff;
                        background-color: rgba(255,255,255,0.6);
                    }

                    .heading {
                        padding-bottom: 10px;
                        @include tablefy(0);

                        @include breakpoint($size-480) {
                            padding-bottom: 0;
                        }
                    }

                    .content-image, .content-text {
                        position: relative;
                        z-index: 1;
                    }

                    .content-image {
                        min-width: 60px;

                        @include breakpoint($size-768) {
                            min-width: 120px;
                        }
                    }

                    .content-text {
                        width: 100%;
                    }

                    .head {
                        margin-bottom: 0;
                        font-weight: $weight-normal;
                        color: $text-color;
                        width: 100%;
                        font-size: em(30px);
                    }

                    .body {
                        font-weight: $weight-bold;
                    }

                    p {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}