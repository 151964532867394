.quote-block {
    blockquote {
        @include breakpoint($size-768) {
            @include span(10);
            @include push(1 or 12);
        }

        @include breakpoint($size-960) {
            @include span(6);
            margin-left: span(5) + gutter(12);
        }
    }
}

.imagequote-block {
    .btn-pdf {
        margin-bottom: 20px;
        display: block;
        text-align: center;
        @include breakpoint($size-768) {
            display: inline-block;
            text-align: left;
            margin-top: 30px;
            margin-left: 40px;
            margin-bottom: 0px;
        }
    }   
}
