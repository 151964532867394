%navigation-block {
	margin-bottom:20px;
    background-color: $gray-lightest;
    .block-title {
        margin: 0;
        padding: 10px;
        background-color: $brand-primary;
        color: $white;
        a {
        	color:white;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        margin-top: 0;
        @include no-bullets;
        li {
            a {
                border-bottom: 1px solid $gray-lighter;
                padding: 5px 10px;
                display: block;
            }
        }
    }
}