.contact-block {
	background-color: $gray-lighter;
	padding: $spacing;
	//@include centred-mobile;

	.detail {
		margin-bottom: $spacing;
		font-size: em(20px);

		@include breakpoint($size-768) {
			@include tablefy(10px, top, true);
		}

		.label {
			color: $brand-primary;

			@include breakpoint($size-768) {
				min-width: 160px;
			}
		}

		.value {
			width: 100%;
		}
	}
}