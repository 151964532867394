.modalcarousel-block {
	.caption {
		padding: 40px;
		padding-bottom: 0;
		min-height: 120px;
		text-align: center;
	}

	.owl-controls {
		height: 0;

		.owl-pagination {
			display: none;
		}

		.owl-buttons {

			.owl-prev, .owl-next {
				position: absolute;
				bottom: 50px;
				text-align: center;
			}

			.owl-prev {
				left: 0;
				margin-left: 0;
			}

			.owl-next {
				right: 0;
				margin-right: 0;
			}
		}
	}
}