.recentnews-block {

	li {
		margin-bottom: 8px;
		font-size: 14px;

		.article-link {
			@include tablefy(0, top);
			color: $text-color;

			&:hover {
				text-decoration: none;
			}

			.date {
				color: $recentnews-date-color;
				white-space: nowrap;
			}

			.title {
				width: 100%;
				font-weight: $weight-bold;
			}
		}
	}
}