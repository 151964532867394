.products-container {
	@include clearfix;
	margin-bottom: $spacing * 2 !important;
}

.product-block {
	margin-bottom: 0;

	&:last-of-type {
		
	}

	// Pull the product up to fit in with product above...
	& + .product-block {
		@include angle-responsive-margin(top);
	}

	& {
		.mask-bottom-left, .mask-top-left {
			&:after, &:before {
				@include angle-color(left, $product-background);
			}
		}
		.product-content {
			background: $product-background;
		}
	}

	&.alt {
		.mask-bottom-right, .mask-top-right {
			&:after, &:before {
				@include angle-color(right, $product-alt-background);
			}
		}
		.product-content {
			background: $product-alt-background;
		}
	}

	.product-content {
		@include breakpoint($size-768) {
			@include tablefy($spacing-mobile, middle);
		}
	}

	.body {
		padding-top: 20px;
		padding-bottom: 20px;

		@include breakpoint($size-768) {
			width: span(7);
			padding-top: 0;
			padding-bottom: 0;
		}

		.body-inner {
			@include tablefy($spacing-mobile, top);

			& > .icon {
				min-width: 50px;

				@include breakpoint($size-768) {
					min-width: 90px;
				}
			}
		}

		.text {
			width: 100%;
		}

		p, h2 {
			margin-bottom: 5px;
		}

		.btn {
			margin-top: $spacing;
		}
	}

	.image {
		text-align: center;
		padding-bottom: $spacing-mobile;

		@include breakpoint($size-768) {
			width: span(5);
		}

		img {
			margin-top: 20px;
		}
	}
}