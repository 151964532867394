@import "helpers/bootstrap sass and mixins/variables";
@import "helpers/bootstrap sass and mixins/mixins/table-row";
@import "helpers/bootstrap sass and mixins/tables";

.block.table-block { // 2 classes to override Bootstraps default classes... forgot how hacky it can be to work with Bootstrap
	&:before {
		background-image: url('/images/background-features.jpg');
		//bottom: 50%;
		bottom: 16.5px;

		@include breakpoint($size-768) {
			bottom: 50%;
		}
	}

	.table-responsive {
		width: 100%;
		margin-bottom: 16.5px;
		overflow-y: hidden;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		//border: 1px solid #636363;
		border: none;
		-webkit-overflow-scrolling: touch;
		position: relative;

		@include breakpoint($size-768) {
			overflow-y: auto;
			overflow-x: auto;
			overflow: auto;
			border: none;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			-webkit-overflow-scrolling: touch;
		}

		&.notify {
			//overflow: hidden;

			.swipenotify {
				display: block;
			}

			.table {
				visibility: hidden;
			}
		}

		.swipenotify {
			display: none;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			pointer-events: none;
			font-size: em(25px);
			text-align: center;
			padding-top: $spacing;
			font-weight: $weight-bold;

			.icon {
				display: inline-block;
				margin-top: $spacing-mobile;
				font-size: 35px;
			}
		}
	}

	h1 {
		margin-top: 20px;
		margin-right: $spacing;
	}

	h1, h3 {
		margin-left: $spacing;
		@include centred-mobile;
	}

	table {
		border-top: none;

		@include breakpoint($size-768) { 
			margin-top: 40 + $spacing;
		}
	}

	th, td {
		text-align: center;
		min-width: 90px;
		max-width: 150px;

		&:last-child {
			border-right: none;
		}
	}

	thead {
		th {
			font-size: 14px;
			vertical-align: top;
			border-bottom: none;
			border-color: $white;
			border-top: 1px solid transparent;

			@include breakpoint($size-960) {
				font-size: 20px;
			}

			img {
				margin: auto;
				display: none;
				border: 1px solid $white;
				margin-top: -40px;
				margin-bottom: 5px;

				@include breakpoint($size-768) {
					display: block;
				}
			}

			.head {
				color: $white;
				font-weight: $weight-normal;
				margin-bottom: 10px;
				margin-top: 10px;

				@include breakpoint($size-960) {
					font-weight: $weight-bold;
				}
			}

			&.corner {
				vertical-align: bottom;

				h3 {
					margin-bottom: 12px;

					@include breakpoint($size-768) {
						margin-bottom: $spacing;
					}
				}
			}
		}
	}

	tbody {
		th, td {
			border-color: $white;
			border-bottom-color: $table-border-color;
		}

		th {
			text-align: left;
			background-color: $gray-light;
			color: $text-color;
			font-weight: $weight-bold;
			max-width: none;
			padding-left: 10px;
			font-size: 12px;
			white-space: nowrap;
			padding-right: 20px;
			vertical-align: middle;

			@include breakpoint($size-768) {
				padding-right: 5px;
			}

			@include breakpoint($size-960) {
				font-size: 14px;
				padding-left: 15px;
			}
		}

		td {
			color: $white;
			white-space: nowrap;

			.shrink {
				font-size: 14px;

				@include breakpoint($size-960) {
					font-size: 16px;
				}
			}

			.shrink-more {
				font-size: 12px;

				@include breakpoint($size-960) {
					font-size: 16px;
				}
			}
		}

		tr:first-child {
			td, th {
				border-top: none;
			}
		}

		tr:last-child {
			td, th {
				border-bottom: 1px solid $white;
			}
		}
	}

	// Colors
	.optimiser-express {
		background-color: $xp-color;

		img {
			border-color: $xp-color;
		}
	}
	.optimiser-express-plus {
		background-color: $xp-plus-color;

		img {
			border-color: $xp-plus-color;
		}
	}
	.optimiser-lite {
		background-color: $lo-color;
	}

    .optimiser-intro {
		background-color: $in-color;
	}
	.optimiser-standard {
		background-color: $so-color;
	}
	.optimiser-professional {
		background-color: $po-color;
	}
	.optimiser-cloud {
		background-color: #3dabff;
	}
	.optimiser-nesting {
		background-color: #FE6809;
	}
}