%button {
    padding: 0.5em 1.3em;
    display: inline-block;
    //background-color: $brand-primary;
    //color: white;
    border: none;
    font-size: $base-font-size * 1.1;
    //margin: 10px 0;
    @include transition-duration(0.3s);
    &:hover {//$left: 0, $top: 2px, $blur: 2px, $spread: 0.2em, $color: #000, $inset: ""
        @include box-shadow(0, 0, 3px, 0px, rgba(0, 0, 0, 0.5), inset);
        text-decoration: none;
    }
    .no-csstransforms &:hover {
        background-color: lighten($brand-primary, 15%);
    }

    @include paraspace;

    &:last-child {
        margin-bottom: 0;
    }
}

.btn {
    @extend %button;

    &.btn-primary {
        background-color: $brand-primary;
        color: $white;
    }

    &.btn-gray {
        background-color: $gray-light;
        color: $text-color;
    }

    &.btn-blue {
        background: $v9-color;
        color: $white;
    }

    &.btn-gray-dark {
        background-color: $gray-lightish;
        color: $text-color;
    }

    &.btn.primary {
        background-color: $gray-lightish;
        color: $text-color;
        width: inherit;
    }

    &.btn-arrowed {
        @extend .icon-next;
        padding-right: 40px;
    }

    &.btn-cloud {
        background-color: #3dabff;
        color: white;
    }

    &.btn-v11 {
        background-color: #934a49;
        color: white;
    }

    &.btn-cloud {
        @extend .icon-next;
        padding-right: 40px;
    }

    .icon-next {
        font-size: 42px;
        vertical-align: middle;
        margin-top: -8px;
        margin-bottom: -10px;
        margin-right: -6px;
        margin-left: 7px;
        display: inline-block;
    }
}