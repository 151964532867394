// # Scaffolding
// --------------------------------------------------

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,800");

// #### Reset the box-sizing
.front-end {
    * {
        @include box-sizing(border-box);
    }
    *:before,
    *:after {
        @include box-sizing(border-box);
    }
    a:hover, a:active, a:focus {
        outline: 0;
    }
}

#canvas * {
    @include box-sizing(border-box);
}


// # Elements
html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    // @include adjust-leading-to(2);
    color: $brand-primary;
}

body {
    font-family: $font-family-base;
    line-height: $base-line-height;
    font-size: $base-font-size;
    color: $text-color;
    font-weight: $weight-normal;
    background-color: $outerbackground-color;

    .front-end {
        position: relative;
        overflow-x: hidden;
        background-color: white;
    }
    //Overrides for the font rendering in the wysiwyg editor
    &.mce-content-body {
        font-size: 14px;
        font-family: $font-family-base;
    }
}

img {
    vertical-align: middle;
    width: auto;
    max-width: 100%;
}

blockquote {
    .quote-wrapped {
        position: relative;
    }

    p {
        font-size: em(18px);
        font-weight: $weight-normal;
        &:before, &:after {
            font-size: 60px;//em(60px);
            line-height: 1em;
            font-weight: $weight-bold;
            position: absolute;

            @include breakpoint($size-768) {
                font-size: 90px;//em(90px);
            }
        }

        &:before {
            content: "“";
            left: -25px;
            top: 0;
            margin-top: -10px;

            @include breakpoint($size-768) {
                left: -40px;
            }
        }

        &:after {
            content: "“";
            position: relative;
            bottom: -35px;
            line-height: 0;
            @include breakpoint($size-768) {
                right: 0px;
                bottom: -52px;
                margin-top: 0px;
            }
        }
        @include breakpoint($size-768) {
            font-size: em(26px);
        }
    }

    cite {
        font-size: em(16px);
        font-style: normal;
        font-weight: $weight-bold;
        display: block;

        @include breakpoint($size-768) {
            font-size: em(18px);
        }
    }
}


// Key layout classes
.page-content {
    max-width: $container-width;
    margin: auto;
    margin-top: 30px;

    @include breakpoint(1240px, "no-query" true) {
        width: $container-width;
    }
}

.background-wrapper {
    @include clearfix;
    max-width: $container-width + 40; // + 40 because we have to wrap margins too and ensure they don't go out the page...
    background-color: $white;
    margin: auto;
    overflow-y: hidden; // This is not exactly a lovely fix... basically, the dropdown plugin flows out of the page on tablet... so... this is the fix to prevent horizontal scrolling in any situation...
    position: relative;
}

// Basically, we want the element to be inline with all other marginned elements at desktop, then if it has fill we'll make it fill below desktop...
.marginned {
    // For mobile, let's add in our margins on blocks
    & {
        margin-left: $spacing-mobile;
        margin-right: $spacing-mobile;
    }

    // 768 to 1200... get elements to be full (floats and adds in margins, makes columning work nicely)
    & {
        @include breakpoint($size-768) {
            @include span(full);
        }
    }
    
    &.fill {
        margin-left: 0;
        margin-right: 0;
    }

    // Beyond 1200, make fill items line up with other items
    &.fill {
        @include breakpoint($size-768) {
            width: 100%;
        }

        @include breakpoint($size-1200) {
            @include span(full);
        }
    }
}

// All blocks... we're just assuming they'll all have spacing on the bottom, but can override when easy enough...
.block {
    margin-bottom: $spacing;

    @include breakpoint($size-768) {
        margin-bottom: $spacing * 2;
    }
}


// #### Hiders

// Only display content to screen readers
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// # Useful global classes
video {
    width: 100% !important;
}

span.skype_pnh_container {
    display: none !important;
}

span.skype_pnh_print_container {
    display: inline !important;
}

.uppercase {
    text-transform: uppercase;
}

.tablefy {
    @include tablefy();
}

.boldest {
    font-weight: $weight-boldest;
}

.fill-width {
    width: 100%;
}

// Gutters for mobile...
.backgrounded {
    position: relative;
    z-index: 1;

    &:before, &:after, .image {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    &:before, .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        //filter: alpha(opacity=80);
    }

    &.background-light:after {
        //background-color: white;
        //@include opacity(0.8);
        background: url(../images/background-light.png);
        background: rgba(255, 255, 255, 0.8);
    }

    // I was trying to get it working for IE8... really it should have already because I was using opacity, but pseudo elements... so eh, I just shoved in an image as fallback instead
    &.background-dark:after {
        //background-color: black;
        //@include opacity(0.45);
        //background: rgba(255, 255, 255, 0.3); /* browsers */
        //filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#4cffffff', endColorstr='#4cffffff'); /* IE */
        /*background:rgb(255,0,0);
        background: transparent\9;
        background:rgba(255,0,0,0.3);
        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#4cFF0000,endColorstr=#4cFF0000);
        zoom: 1;*/
        //background-color: rgba(0, 0, 0, 0.45);
        //-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#550000FF, endColorstr=#550000FF)";
        background: url(../images/background-dark.png);
        background: rgba(0, 0, 0, 0.45);
    }

    &.background-dark {
        color: white;
    }
}

.clear {
    // At tablet and beyond, clearfix because we are using columns...
    @include breakpoint($size-768) {
        clear: both;
        &:after {
            content: " ";
            display: block;
            clear: both;
        }
    }
}

.bordered {
    border: 1px solid $gray-light;
}

.link-iconned {
    color: $text-color;
    font-weight: $weight-bold;

    .icon {
        display: inline-block;
        font-size: 30px;
        vertical-align: middle;
        margin-top: -1px;
        width: 30px;
        color: $brand-primary;
    }

    &:hover {
        .icon {
            text-decoration: none;
        }
    }
}

.spacing-bottom {
    @include paraspace;
}

.breadcrumb {
    visibility: hidden;

    @include breakpoint($size-480) {
        visibility: visible;
    }
}


#heading ol li, .wysiwyg ol li {
    list-style-type: decimal;
    margin-left: 50px;
    &:before {
        display: none;
    }
}